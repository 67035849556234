
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons,
  IonBackButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
  }
});
