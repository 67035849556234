import { PatientModel } from '../models/PatientModel';

import EventBus from './EventBus';
import DatabaseService from './DatabaseService';
import LogService from './LogService';
import { ValueChanger } from './ValueChanger';

export class PatientService {
	
	private static _patient : PatientModel;
	private static _instance : PatientService;

	constructor() {
		if (PatientService._patient == null) {
			PatientService._patient = new PatientModel();

			EventBus.addEventListener('shocked', () => {
				// zaaaaap!
				PatientService.getInstance().shocked();
			});
		}
	}

	public static getInstance() : PatientService {
		if (!this._instance) {
			this._instance = new PatientService();
		}

		return this._instance;
	}
	
	public getPatient() {
		if (PatientService._patient == null) {
			PatientService._patient = new PatientModel();
		}

		return PatientService._patient;
	}

	public isShockable() :boolean {
		return DatabaseService.isShockable(this.getRhythmBefore('ecg'));
	}
	
	public getDisplayValue(system :string, identifier :string) {
		return ''+this.getPatient()['patient'][system][identifier]['display'];
	}
	
	public updateDisplayValue(system :string, identifier :string) {
		if (this.isConnected(system)) {
			const before = this.getPatient()['patient'][system][identifier]['before'];
			this.getPatient()['patient'][system][identifier]['display'] = before;

			EventBus.emit('updateDisplayValue', {system: system, identifier: identifier, value: before});

			// updating nibp?
			if (system == 'nibp') {
				if (this.getPatient()['patient']['nibp']['sys']['display'] != '--' && this.getPatient()['patient']['nibp']['dia']['display'] != '--') {
					this.getPatient()['patient']['nibp']['combined']['display'] = this.getPatient()['patient']['nibp']['sys']['display']+'/'+this.getPatient()['patient']['nibp']['dia']['display'];
				
					EventBus.emit('updateDisplayValue', {system: system, identifier: 'combined', value: this.getPatient()['patient']['nibp']['combined']['display']});
				}
			}
		} else {
			this.getPatient()['patient'][system][identifier]['display'] = '--';
			EventBus.emit('updateDisplayValue', {system: system, identifier: identifier, value: '--'});
		}
	}
	
	public getValue(system :string, identifier :string, type :string) {
		return this.getPatient()['patient'][system][identifier][type];
	}

	public getRhythmBefore(system :string) {
		const patient = this.getPatient();
		return patient['patient'][system]['rhythm']['before'];
	}

	public getRhythmAfter(system :string) {
		return this.getPatient()['patient'][system]['rhythm']['after'];
	}

	public updateRhythmBefore(system :string, id :number) {
		const patient = this.getPatient();
		patient['patient'][system]['rhythm']['before'] = id;

		EventBus.emit('updateRhythmBefore', {system: system, value: id});
	}

	public updateRhythmAfter(system :string, id :number) {
		const patient = this.getPatient();
		patient['patient'][system]['rhythm']['after'] = id;

		EventBus.emit('updateRhythmAfter', {system: system, value: id});
	}

	public updateRhythm(system :string, type :string, id :number) {
		if (type == 'before') {
			this.updateRhythmBefore(system, id);
		} else {
			this.updateRhythmAfter(system, id);
		}
	}
	
	public updateValue(system :string, identifier :string, type :string, value :any) {
		if (system == 'spo2' && identifier == 'pp') {
			/* spo2.pp will be updated by ecg.hf changes */
			this.getPatient()['patient'][system][identifier][type] = this.getPatient()['patient']['ecg']['hf'][type];
		}
		else {
			this.getPatient()['patient'][system][identifier][type] = value;
		}

		if (this.isConnected(system) && system != 'nibp') {
			this.updateDisplayValue(system, identifier);
		}

		// dirty hack for also updating spo2.pp
		if (system == 'ecg' && identifier == 'hf') {
			this.updateValue('spo2', 'pp', type, value);
		}

		// updating nibp?
		if (system == 'nibp') {
			if (this.getPatient()['patient']['nibp']['sys']['display'] != '--' && this.getPatient()['patient']['nibp']['dia']['display'] != '--') {
				this.getPatient()['patient']['nibp']['combined']['display'] = this.getPatient()['patient']['nibp']['sys']['display']+'/'+this.getPatient()['patient']['nibp']['dia']['display'];
			}

			// send multiValue update
			EventBus.emit('multiValue', {system: system, value: -1 * Math.abs(this.getPatient()['patient']['nibp']['sys']['before'] - this.getPatient()['patient']['nibp']['dia']['before'])});
		}

		if (type === 'before') {
			EventBus.emit('updateValue', {system: system, identifier: identifier, value: value});
		}
	}
	
	public isConnected(system :string) {
		return PatientService._patient.getConnector()[system];
	}
	
	public connect(system :string) {
		const updateLog = !PatientService._patient.getConnector()[system];

		PatientService._patient.getConnector()[system] = true;
		//this.connectorSubject.next({system: system, status: true});
		
		// this is dirty but anyways...
		if (system == 'ecg') {
			//this.getPatient()['patient'][system]['hf']['display'] = this.getPatient()['patient'][system]['hf']['before'];
			this.updateValue(system, 'hf', 'before', this.getPatient()['patient'][system]['hf']['before']);
		}
		
		if (system == 'spo2') {
			//this.getPatient()['patient'][system]['value']['display'] = this.getPatient()['patient'][system]['value']['before'];
			//this.getPatient()['patient'][system]['pp']['display'] = this.getPatient()['patient'][system]['pp']['before'];

			this.updateValue(system, 'value', 'before', this.getPatient()['patient'][system]['value']['before']);
			this.updateValue(system, 'pp', 'before', this.getPatient()['patient'][system]['pp']['before']);
		}
		
		if (system == 'etco2' || system == 'co2') {
			//this.getPatient()['patient'][system]['value']['display'] = this.getPatient()['patient'][system]['value']['before'];
			//this.getPatient()['patient'][system]['af']['display'] = this.getPatient()['patient'][system]['af']['before'];

			this.updateValue(system, 'value', 'before', this.getPatient()['patient'][system]['value']['before']);
			this.updateValue(system, 'af', 'before', this.getPatient()['patient'][system]['af']['before']);
		}

		if (updateLog) {
			EventBus.emit('patientSystemIsConnected', {system: system, value: true});
		}

		if (system == 'etco2' && updateLog) {
			EventBus.emit('patientSystemIsConnected', {system: 'co2', value: true});
		}
	}
	
	public disconnect(system :string) {
		const updateLog = PatientService._patient.getConnector()[system];

		PatientService._patient.getConnector()[system] = false;

		if (system == 'ecg') {
			//this.getPatient()['patient'][system]['hf']['display'] = '--';
			this.updateDisplayValue(system, 'hf');
		}
		
		if (system == 'spo2') {
			//this.getPatient()['patient'][system]['value']['display'] = '--';
			//this.getPatient()['patient'][system]['pp']['display'] = '--';

			this.updateDisplayValue(system, 'value');
			this.updateDisplayValue(system, 'pp');
		}
		
		if (system == 'etco2' || system == 'co2') {
			//this.getPatient()['patient'][system]['value']['display'] = '--';
			//this.getPatient()['patient'][system]['af']['display'] = '--';

			this.updateDisplayValue(system, 'value');
			this.updateDisplayValue(system, 'af');
		}

		if (updateLog) {
			EventBus.emit('patientSystemIsConnected', {system: system, value: false});
		}

		if (system == 'etco2' && updateLog) {
			EventBus.emit('patientSystemIsConnected', {system: 'co2', value: false});
		}
	}
	
	public shocked() {
		EventBus.emit('shockSuccessful', true);
		// update rhythm
		this.updateRhythmBefore('ecg', this.getRhythmAfter('ecg'));
		this.updateRhythmBefore('spo2', this.getRhythmAfter('spo2'));
		this.updateRhythmBefore('etco2', this.getRhythmAfter('etco2'));

		this.updateValue('ecg', 'hf', 'before', this.getValue('ecg', 'hf', 'after'));

		this.updateValue('spo2', 'pp', 'before', this.getValue('spo2', 'pp', 'after'));
		this.updateValue('spo2', 'value', 'before', this.getValue('spo2', 'value', 'after'));

		this.updateValue('etco2', 'value', 'before', this.getValue('etco2', 'value', 'after'));
		this.updateValue('etco2', 'af', 'before', this.getValue('etco2', 'af', 'after'));

		this.updateValue('nibp', 'sys', 'before', this.getValue('nibp', 'sys', 'after'));
		this.updateValue('nibp', 'dia', 'before', this.getValue('nibp', 'dia', 'after'));
	}

	public updateFromWebsocket(ident :string, val :number, instant = false) {
		switch(ident) {
			case 'ecgValue': ValueChanger.getInstance().updateValue('ecg', 'hf', val, instant); break;
			case 'ecgValueAfterDefib': this.updateValue('ecg', 'hf', 'after', val); break;

			case 'etco2Value': ValueChanger.getInstance().updateValue('etco2', 'value', val, instant); break;
			case 'etco2ValueAfterDefib': this.updateValue('etco2', 'value', 'after', val); break;
			case 'etco2Rf': ValueChanger.getInstance().updateValue('etco2', 'af', val, instant); break;
			case 'etco2RfAfterDefib': this.updateValue('etco2', 'af', 'after', val); break;

			case 'nibpSys': ValueChanger.getInstance().updateValue('nibp', 'sys', val, instant); break;
			case 'nibpSysAfterDefib': this.updateValue('nibp', 'sys', 'after', val); break;
			case 'nibpDia': ValueChanger.getInstance().updateValue('nibp', 'dia', val, instant); break;
			case 'nibpDiaAfterDefib': this.updateValue('nibp', 'dia', 'after', val); break;

			case 'spo2Value': ValueChanger.getInstance().updateValue('spo2', 'value', val, instant); break;
			case 'spo2ValueAfterDefib': this.updateValue('spo2', 'value', 'after', val); break;
			case 'spo2Pp': ValueChanger.getInstance().updateValue('spo2', 'pp', val, instant); break;
			case 'spo2PpAfterDefib': this.updateValue('spo2', 'pp', 'after', val); break;

			default:
				console.log(`${ident} not updated`);
		}
	}
}

export default PatientService.getInstance();