import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import MonitorPage from '../views/MonitorPage.vue'
import SupportPage from '../views/SupportPage.vue'
import ImpressPage from '../views/Support/ImpressPage.vue'
import FAQPage from '../views/Support/FAQPage.vue'
import FirstStepsPage from '../views/Support/FirstStepsPage.vue'
import SplashPage from '../views/components/SplashPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/splash'
  },
  /**
   * Main pages
   */
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: MonitorPage
  },
  {
    path: '/support',
    name: 'Support',
    component: SupportPage
  },
  /**
   * Support pages
   */
  {
    path: '/support/impress',
    name: 'Impressum',
    component: ImpressPage
  },
  {
    path: '/support/faq',
    name: 'FAQ',
    component: FAQPage
  },
  {
    path: '/support/firststeps',
    name: 'Erste Schritte',
    component: FirstStepsPage
  },
  {
    path: '/splash',
    name: 'Splash',
    component: SplashPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router