export class EventBusEvent extends Event {
  public data: any;

  constructor({type, data} : {type: string, data: any}) {
    super(type)
    this.data = data
  }
}

export class EventBusLogEvent extends Event {
  public data: any;
  public type: string;

  constructor({type, data} : {type: string, data: {type:string, data:string}}) {
    super(type);
    this.data = data.data;
    this.type = data.type;
  }
}


class EventBus extends EventTarget {
  private static _instance: EventBus

  public static getInstance() : EventBus {
    if (!this._instance) this._instance = new EventBus();
    return this._instance
  }

  public emit(type : string, data?: any) : void {
    this.dispatchEvent(new EventBusEvent({type, data}));

    // emit to * listener, except WalkingGraph
    if (!['walkingGraph', 'pulse'].includes(type)) {
      this.dispatchEvent(new EventBusLogEvent({type: '*', data: {type: type, data: data}}));
    }
  }

  public getData(event: Event) : null | any {
    if (event instanceof EventBusEvent) {
      return event.data;
    } else if (event instanceof EventBusLogEvent) {
      return event;
    }

    return null;
  }
}

export default EventBus.getInstance();