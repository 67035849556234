import PatientService from './PatientService';
import LogService from './LogService';

export class ValueChanger {
	private static _instance :ValueChanger;

	private stepperStorage: Map<any, number>;

	private valueChangerSettings :any = {
		'ecg': {
			hf: {time: 20, minDiff: 10},
		},
		'spo2': {
			value: {time: 20, minDiff: 10},
			pp: {time: 20, minDiff: 10},
		},
		'nibp': {
			sys: {time: 20, minDiff: 10},
			dia: {time: 20, minDiff: 10},
			combined: {display: '--/--'},
		},
		'etco2': {
			value: {time: 20, minDiff: 2},
			af: {time: 20, minDiff: 2},
		},
		'ibp': {
			//
		}
	};

	private timeout = 1000;

	constructor() {
		//
		this.stepperStorage = new Map<any, number>();
	}

	public static getInstance() {
		if (this._instance == null) {
			this._instance = new ValueChanger();
		}

		return this._instance;
	}

	public updateSetting(system :string, identifier :string, value :number) {
		this.valueChangerSettings[system][identifier].time = value;
	}

	public updateValue(system :string, identifier :string, targetValue :number, instant = false) {
		const type = 'before';
		
		if (this.stepperStorage.has(system.concat(identifier))) {
			clearTimeout(this.stepperStorage.get(system.concat(identifier)));
			this.stepperStorage.set(system.concat(identifier), -1);
		}
		
		const currentValue = Math.max(PatientService.getValue(system, identifier, type), 0);
			
		let time = this.valueChangerSettings[system][identifier]['time'];
			
		if (instant) {
			time = 1;
		}
			
		// check update?
		if (currentValue == targetValue) {
			return true;
		}
		
		// log value changer
		LogService.logVital(system, identifier, currentValue, targetValue, time);
		
		const diff = Math.abs(currentValue - targetValue);
		let stepSize = Math.floor(diff/Math.max(time, 1));
		
		// set instant?
		const minDiff = this.valueChangerSettings[system][identifier]['minDiff'];
		if (minDiff >= diff) {
			// use a default of 1 -> instant update
			time = 1;
		}
			
		let counter = time;
			
		// so we have a small change over a loooong period
		if (stepSize == 0) {
			stepSize = 1;
			this.timeout = (time/diff)*1000;
			counter = diff;
		}
			
		//this.stepperStorage.set(system.concat(identifier), -1);
		
		this.stepper(system, identifier, currentValue, stepSize, targetValue, counter);
	}

	public stepper(system :string, identifier :string, start :number, step :number, end :number, counter :number) {
		// eslint-disable-next-line 
		let that = this;

		const key = system.concat(identifier);
		console.log(`${system}, ${identifier}, ${key}`);

		let val;
		if (start < end) {
			val = end - step*(counter-1);
		} else if (start > end) {
			val = end + step*(counter-1);
		} else {
			val = end;
			counter = 0;
		}
			
		// update patient
		PatientService.updateValue(system, identifier, 'before', val);

		// decrement counter
		counter--;
		console.log(`counter now is ${counter}`);
		
		if (counter > 0) {
			const idx = setTimeout(function() {
				const stepFn = that.stepperStorage.get(key);

				console.log(`stepFn: ${stepFn}, ${key}`);
				if (typeof stepFn !== 'undefined' && stepFn >= -1) {
					console.log(`calling stepper(${start}, ${step}, ${end}, ${counter})`);
					that.stepper(system, identifier, start, step, end, counter);
				}
			}, this.timeout);

			this.stepperStorage.set(key, idx);
		} else {
			return true;
		}
	}
}

export default ValueChanger.getInstance();