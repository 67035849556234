
import { IonContent, IonHeader, IonPage, IonToolbar, IonModal, IonIcon, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import MonitorMenuComponent from './components/MonitorMenuComponent.vue';
import MonitorVital from './components/MonitorVital.vue';
import MonitorGraphInstance from './components/MonitorGraphInstance.vue';
import MonitorConnectionModal from "./components/Monitor/MonitorConnectionModal.vue";
import HeartBeat from './components/HeartBeat';
import PlethTone from './components/Monitor/PlethTone';

// Monitor Partials
import MonitorAed from './components/Monitor/MonitorAed.vue';
import MonitorDefi from './components/Monitor/MonitorDefi.vue';
import MonitorNibp from './components/Monitor/MonitorNibp.vue';
import DisplayAttachement from './components/DisplayAttachement.vue';

import DatabaseService from '../services/DatabaseService';
import EventBus from '../services/EventBus';
import LogService from '../services/LogService';
import PatientService from '../services/PatientService';
import ValueChanger from '../services/ValueChanger';
import SocketClientService from '../services/SocketClientService';

import { settingsOutline } from 'ionicons/icons';

let walkingGraphPause = false;
let pxPerms = 3;//5
let walkingGraphPointer = 0;
let datapoints = 820;

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonModal, IonButton,
    IonIcon,
    MonitorMenuComponent, MonitorAed, MonitorDefi, MonitorNibp,
    MonitorVital,
    MonitorGraphInstance,
    MonitorConnectionModal,
    DisplayAttachement,
  },
  setup() {
    const router = useRouter();
    LogService.init();

    return {
      settingsOutline,
      router
    };
  },
  data() {
    return {
      state: 'monitor',
      hf: '--',
      spo2value: '--',
      spo2pp: '--',
      nibp: '--/--',
      etco2value: '--',
      etco2af: '--',

      // Info for displaying the connection modal
      connectModalInfo: {
        showMe: false,
      },

      graphEcgOptions: {
          lead: 'ecg',
					startPosition: 'middle',
					width: null,
					height: null,
          onShock: true,
          //emitPulse: true,
          onPulse: true,
					strokeStyleNormal:  '#06d706',
					strokeStyleInverted: '#13ad13',
      },

      graphSpO2Options: {
          lead: 'spo2',
					startPosition: 'bottom',
          strokeStyleNormal:  '#01e9e9',
          strokeStyleInverted: '#0c5ae3',
          onPulse: true,
          multiValueRef: 'nibp'
      },

      graphEtCO2Options: {
					lead: 'co2',
					startPosition: 'bottom',
          strokeStyleNormal: '#790196',
          strokeStyleInverted: '#a40edc',
          fill: true,
          fillStyleNormal: '#790196',
          fillStyleInverted: '#a40edc',
      },

      graphIBDOptions: {
					lead: 'ibp',
					startPosition: 'bottom',
          strokeStyleNormal:  '#c00000',
			    strokeStyleInverted: '#c00000',
      },

      walkingGraphIDX: setInterval.prototype,

      watchResume: false,

      //
      heartBeat: new HeartBeat(),
      plethTone: new PlethTone(),

      darkmode: true,
      ecgMenuEnabled: !true,

      ready: false
    };
  },
  mounted() {
    // eslint-disable-next-line
    let that = this;

    EventBus.addEventListener('menuStateChangeEvent', (event) => {
      that.state = EventBus.getData(event);
    });

    EventBus.addEventListener('goHome', (event) => {
      this.router.push('/home');
    });

    EventBus.addEventListener('updateDisplayValue', (event) => {
      let data = EventBus.getData(event);

      if (data.system == 'ecg' && data.identifier == 'hf') {
        that.hf = ''+data.value;
      } else if (data.system == 'spo2' && data.identifier == 'value') {
        that.spo2value = ''+data.value;
      } else if (data.system == 'spo2' && data.identifier == 'pp') {
        that.spo2pp = ''+data.value;
      } else if (data.system == 'etco2' && data.identifier == 'value') {
        that.etco2value = ''+data.value;
      } else if (data.system == 'etco2' && data.identifier == 'af') {
        that.etco2af = ''+data.value;
      } else if (data.system == 'nibp' && data.identifier == 'combined') {
        that.nibp = ''+data.value;
      }
    });

    (window as any).vc = ValueChanger;
    (window as any).ps = PatientService;
    (window as any).ls = LogService;
    (window as any).ds = DatabaseService;
    
    	console.log('syncPatient()');
      SocketClientService.syncPatient().then(() => {
      	console.log('syncPatient(): finished, starting simulation');
      	this.startSimulation();
      });
  },
  methods: {
    startSimulation: async function() {
      // set darkmode
      this.darkmode = (await DatabaseService.getValue('settings', 'darkmode')).value == 'true' ? true:false;

      // set ecgMenu enabled
      this.ecgMenuEnabled = (await DatabaseService.getValue('settings', 'ecgmenu')).value == 'true' ? true:false;

      this.hf = PatientService.getDisplayValue('ecg', 'hf');
      this.spo2value = PatientService.getDisplayValue('spo2', 'value');
      this.spo2pp = PatientService.getDisplayValue('spo2', 'pp');
      this.etco2value = PatientService.getDisplayValue('etco2', 'value');
      this.etco2af = PatientService.getDisplayValue('etco2', 'af');

      setTimeout(() => {
        let width = (this.$refs.widthGetDiv as HTMLDivElement).clientWidth;
        datapoints = 2*Math.floor(width *0.8) -5; // random offset

        this.walkingGraphIDX = setInterval(() => {this.walkingGraph();}, 20);//40
        this.ready = true;
      }, 5000);
    },
    /**
     * called to close the modal in response to
     * cancelled event emitted
     */
    handleModalCancelled: function(_event: any) {
      console.log(_event);
      this.connectModalInfo.showMe = false;
    },
    setOpen: function(show: boolean) {
      this.connectModalInfo.showMe = show;
    },
    walkingGraph: function() {
    	if (!walkingGraphPause) {
    		// update active graphs
          //this.activeGraphs[x].getNextData(this.walkingGraphPointer, pxPerms);
        EventBus.emit('walkingGraph', {pointer: walkingGraphPointer, pxPerms: pxPerms});
    	}

    	if (!walkingGraphPause) {
    		walkingGraphPointer += pxPerms;
    	}
        
      if (walkingGraphPointer > datapoints) {
        walkingGraphPointer = 0;
      }
    },
    showModal: function() {
      this.setOpen(true);
    }
  }
});
