
import { defineComponent } from 'vue';
import { IonButton,IonIcon,actionSheetController, modalController } from '@ionic/vue';
import AttachementModal from './AttachementModal.vue';

import { documentOutline,documentTextOutline } from 'ionicons/icons';

import EventBus from '../../services/EventBus';

export default defineComponent({
	name: 'DisplayAttachement',
	components: { IonButton,IonIcon },
	setup() {
		let attachements: any[] = [];
		return { documentOutline,documentTextOutline, attachements }
	},
	data() {
		return {
			unreadDoc: false,
		};
	},
	mounted() {
		EventBus.addEventListener('attachement',(event: any) => {
			this.unreadDoc = true;

			const name = event.data.name;
			const link = event.data.link;

			this.attachements.push({
				time: Date.now(),
				name: name,
				link: link
			});
		});
	},
	methods: {
		openSelected(event: CustomEvent) {
			console.log(event);
		},
		async presentDocumentList() {
			let documents :any[] = [];

			for(let o of this.attachements) {
				documents.push({
					text: o.name,
					role: 'attachement',
					data: {
						name: o.name,
						link: o.link,
					},
				});
			}

			documents.push({
				text: this.$t('connectionModal.wifi.close'),
				role: 'cancel',
				data: {
					action: 'cancel',
				},
			});

			const actionSheet = await actionSheetController.create({
				header: 'Dokumente',
				buttons: documents,
			});

			await actionSheet.present();
			let { data,role }=await actionSheet.onDidDismiss();
			console.log(data,role);
			
			if (role === 'attachement') {
				// open modal
				this.openModal(data);
			}
		},
		async openModal(event: any) {
			const modal = await modalController.create({
				component: AttachementModal,
				componentProps: {attachement: event},
				cssClass: 'fullscreen'
			});
			
			modal.present();

			await modal.onWillDismiss();
		}
	}
})
