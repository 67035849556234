import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { collapse: "condense" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: _ctx.$t('general.back'),
                    "default-href": "home"
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, {
                innerHTML: _ctx.$t('support.title')
              }, null, 8, ["innerHTML"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, {
                    "router-direction": "replace",
                    "router-link": "/support/firststeps",
                    button: "true"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_subtitle, {
                            innerHTML: _ctx.$t('support.cards.howto.title')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_card_title, {
                            innerHTML: _ctx.$t('support.cards.howto.headline')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, {
                        innerHTML: _ctx.$t('support.cards.howto.content')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, {
                    "router-direction": "replace",
                    "router-link": "/support/faq",
                    button: "true"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_subtitle, {
                            innerHTML: _ctx.$t('support.cards.faq.title')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_card_title, {
                            innerHTML: _ctx.$t('support.cards.faq.headline')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, {
                        innerHTML: _ctx.$t('support.cards.faq.content')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, {
                    "router-direction": "replace",
                    "router-link": "/support/impress",
                    button: "true"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_subtitle, {
                            innerHTML: _ctx.$t('support.cards.legal.title')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_card_title, {
                            innerHTML: _ctx.$t('support.cards.legal.headline')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, {
                        innerHTML: _ctx.$t('support.cards.legal.content')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card, {
                    href: "https://my.bugasura.io/mdvlpmnt/simutab-light",
                    button: "true"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_subtitle, {
                            innerHTML: _ctx.$t('support.cards.bug.title')
                          }, null, 8, ["innerHTML"]),
                          _createVNode(_component_ion_card_title, {
                            innerHTML: _ctx.$t('support.cards.bug.headline')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_content, {
                        innerHTML: _ctx.$t('support.cards.bug.content')
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, { style: {"color":"#000"} }, {
                default: _withCtx(() => [
                  _createTextVNode("MDVLPMNT - Made with <3 in Mainz")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}