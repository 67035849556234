
import { defineComponent } from 'vue';

import GraphInstance from './GraphInstance';
import EventBus from '../../services/EventBus';
import DatabaseService from '../../services/DatabaseService';
import PatientService from '../../services/PatientService';

export default defineComponent({
	name: 'MonitorGraphInstance',
	props: {
		title: String,
		options: {type: Object, required: true}
	},
	data() {
		return {
			t: GraphInstance.prototype,
			valueMultiRef: '',
		};
	},
	mounted() {
		this.resizeCanvas();

		// eslint-disable-next-line
		let that = this;

		//
		if (typeof this.options.multiValueRef !== 'undefined') {
			this.valueMultiRef = this.options.multiValueRef;
		}

		// patientSystemIsConnected
		EventBus.addEventListener('patientSystemIsConnected', (event) => {
			let data = EventBus.getData(event);

			if (data.system == that.options?.lead) {
				//
				that.t.setConnected(data.value);
				console.log(`patientSystemIsConnected: Setting graph(${data.system}) to ${data.value}`);
			}
		});

		EventBus.addEventListener('multiValue', (event) => {
			let data = EventBus.getData(event);

			if (data.system == this.valueMultiRef) {
				//
				const val = data.value > -40 ? -40 : data.value;
				this.t.valueMultiCalculated = data.value;
				console.log(`${this.valueMultiRef} valueMultiCalculated is now ${data.value}`);
			}
		});

		EventBus.addEventListener('updateDisplayValue', (event) => {
			let data = EventBus.getData(event);

			let ident = data.system;
			let value = data.value;
			let update = false;

			if (data.system == that.options?.lead) {
				if (data.system == 'ecg' && data.identifier == 'hf') {
					value = data.value;
				} else if (data.system == 'spo2' && data.identifier == 'pp') {
					value = data.value;
				} else if ((data.system == 'co2' || data.system == 'etco2') && data.identifier == 'af') {
					value = data.value;
				}

				update = true;
			}

			if (update) {
				let id = PatientService.getRhythmBefore(ident);
				DatabaseService.table(ident).where({parentid: ''+id, heartrate: value}).first().then(d => {
					let json;
					if (ident == 'ecg') {
						json = d.ecgII;
					} else {
						json = d.graph;
					}

					that.t.setJson('connected', json);
				});
			}
		});

		EventBus.addEventListener('updateRhythmBefore', (event) => {
			let data = EventBus.getData(event);

			let ident = data.system;
			let value = data.value;//Rhythm
			let update = false;
			let rate = 1;

			if (data.system == that.options?.lead) {
				if (data.system == 'ecg' ) {
					rate = PatientService.getValue('ecg', 'hf', 'before');
				} else if (data.system == 'spo2') {
					rate = PatientService.getValue('spo2', 'pp', 'before');
				} else if (data.system == 'co2') {
					rate = PatientService.getValue('etco2', 'af', 'before');
				}

				update = true;
			}

			if (update) {
				DatabaseService.table(ident).where({parentid: ''+value, heartrate: rate}).first().then(d => {
					console.log(`Requesting data: ${ident} ${value} ${rate}`, d);
					if (typeof d === 'undefined') {
						DatabaseService.table(ident).toArray().then(d => {
							console.log(d);
						})
					}

					let json;
					if (ident == 'ecg') {
						json = d.ecgII;
					} else {
						json = d.graph;
					}

					that.t.setJson('connected', json);
				});
			}
		});

		EventBus.addEventListener('updateRhythmAfter', (event) => {
			let data = EventBus.getData(event);

			let ident = data.system;
			let value = data.value;//Rhythm
			let update = false;
			let rate = 1;

			if (data.system == that.options?.lead) {
				if (data.system == 'ecg' ) {
					rate = PatientService.getValue('ecg', 'hf', 'after');
				} else if (data.system == 'spo2') {
					rate = PatientService.getValue('spo2', 'pp', 'after');
				} else if (data.system == 'co2') {
					rate = PatientService.getValue('etco2', 'af', 'after');
				}

				update = true;
			}

			if (update) {
				DatabaseService.table(ident).where({parentid: ''+value, heartrate: rate}).first().then(d => {
					console.log(`Requesting data: ${ident} ${value} ${rate}`, d);
					if (typeof d === 'undefined') {
						DatabaseService.table(ident).toArray().then(d => {
							console.log(d);
						})
					}

					let json;
					if (ident == 'ecg') {
						json = d.ecgII;
					} else {
						json = d.graph;
					}

					that.t.setJson('afterDefib', json);
				});
			}
		});

		EventBus.addEventListener('shocked', () => {
			// zaaaaap!
			that.t.shock(true);
		});

		// EventBus.emit('walkingGraph', {pointer: walkingGraphPointer, pxPerms: pxPerms});
		EventBus.addEventListener('walkingGraph', (event) => {
			let data = EventBus.getData(event);

			that.t.getNextData(data?.pointer, data.pxPerms);
		});
	},
	methods: {
		resizeCanvas: function() {
			// eslint-disable-next-line 
			let that = this;
			this.$nextTick(() => {
				var w = (that.$refs.canvasContainer as HTMLCanvasElement).clientWidth;
				var h = (that.$refs.canvasContainer as HTMLCanvasElement).clientHeight;

				if (w === 0) {
					// this is so dirty even I am ashamed because $nextTick() renders TOO early...
					setTimeout(() => {that.resizeCanvas();}, 500);
					//
					return;
				}
				//console.log(that.$refs.canvas.width, that.$refs.canvas.height, that.$refs.canvas.$el.clientHeight);

				// styling magic: target.append('<canvas width="'+2*this.width+'" height="'+this.height+'" style="top: 0px; left: 30px;width:'+this.width+'px;height:'+this.height+'px;">');
				(that.$refs.canvas as HTMLCanvasElement).width = 2*w;
				(that.$refs.canvas as HTMLCanvasElement).height = h;
				(that.$refs.canvas as HTMLCanvasElement).setAttribute('style', `width: ${w}px`);

				this.setupGI(this.options);
			});
		},
		setupGI: async function(options :any) {
			let ctx = (this.$refs.canvas as HTMLCanvasElement).getContext('2d');

			if (ctx == null) {
				return;
			}

			options.width = ctx.canvas.width;
			options.height = ctx.canvas.height;
	  
			this.t = new GraphInstance(ctx, [[0, -10]], options);

			//
			const darkmode = (await DatabaseService.getValue('settings', 'darkmode')).value == 'true' ? true:false;
			this.t.inverted(!darkmode);

			// eslint-disable-next-line
			let that = this;

			let system = this.options?.lead;

			let identifier = '';
			if (system == 'ecg') {
				identifier = 'hf'
			} else if (system == 'spo2' ) {
				identifier = 'pp';
			} else if (system == 'co2') {
				identifier = 'af';
				system = 'etco2';
			}

			let id = PatientService.getRhythmBefore(system);

			console.log(`value request: ${system} ${identifier}`);
			let value = PatientService.getValue(system, identifier, 'before');
			DatabaseService.table(system).where({parentid: ''+id, heartrate: value}).first().then(d => {
				console.log(`Requesting data: ${system} ${id} ${value}`, d);
				let json;
				if (system == 'ecg') {
					json = d.ecgII;
				} else {
					json = d.graph;
				}

				this.t.setJson('connected', json);

				console.log(`setupGI (${system}): connected = ${PatientService.isConnected(system)}`);
				this.t.setConnected(PatientService.isConnected(system));
			});

			let idAfter = PatientService.getRhythmAfter(system);
			let valueAfter = PatientService.getValue(system, identifier, 'after');
			DatabaseService.table(system).where({parentid: ''+idAfter, heartrate: valueAfter}).first().then(d => {
				console.log(`Requesting data (after defib): ${system} ${idAfter} ${valueAfter}`, d);
				let json;
				if (system == 'ecg') {
					json = d.ecgII;
				} else {
					json = d.graph;
				}

				that.t.setJson('afterDefib', json);
			});

			// update multiValue if NIBP
			if (this.valueMultiRef == 'nibp') {
				this.t.valueMultiCalculated  = -1 * Math.abs(PatientService.getValue('nibp', 'sys', 'before') - PatientService.getValue('nibp', 'dia', 'before'));
			}

			if (typeof this.options.emitPulse !== 'undefined' && this.options.emitPulse) {
				//
				this.t.emitPulse = () => {EventBus.emit('pulse', null);};
			}

			if (this.options?.onPulse) {
				EventBus.addEventListener('pulse', () => {
					// request a drawing
					this.t.requestDraw();
				});
			}
		}
	}
});
