
import { defineComponent } from 'vue';
import { useIonRouter } from '@ionic/vue';


// https://codepen.io/superchief/pen/mJmoBz

export default defineComponent({
	name: 'SplashPage',
	components: {},
	props: {},
	data() {
		return {}
	},
	setup() {
		const ionRouter = useIonRouter();

		setTimeout(() => {
			ionRouter.push('/home');
		}, 7500);
		return {};
	},

	methods: {
	}
});
