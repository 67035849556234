import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_button, {
      size: "small",
      class: "display-button",
      onClick: _ctx.presentDocumentList
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_ion_icon, { icon: _ctx.documentOutline }, null, 8, ["icon"]), [
          [_vShow, !_ctx.unreadDoc]
        ]),
        _withDirectives(_createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"]), [
          [_vShow, _ctx.unreadDoc]
        ])
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}