import EventBus from './EventBus';

export class LogEntry {
	public time: number;
	public type: string;
	public value: any;

	constructor(type :string, value :any) {
		this.time = Date.now();
		this.type = type;
		this.value = value;
	}
}

export class LogService {
	private static _instance: LogService;

	private _log :LogEntry[] = [];

	constructor() {
		EventBus.addEventListener('*', (event :any) => {
			  //that.state = EventBus.getData(event);
			  console.log(`Event(LogService): ${JSON.stringify(event)}`);

			  const data = EventBus.getData(event);
			  console.log(`${JSON.stringify(data.type)} - ${JSON.stringify(data.data)}`)

			  if (typeof data.type !== 'undefined') {
				  switch(data.type) {
					  case 'btnPress': this.logButton(data.data); break;
					  case 'menuStateChangeEvent': this.logView(data.data); break;
					  case 'patientSystemIsConnected': this.logConnection(data.type, data.data.value); break;
					  case 'shockSuccessful': this.logShock(data.data.value); break;
				  }
			  }
    	});
	}

	public static getInstance(): LogService {
		if (!this._instance) {
			this._instance = new LogService();
		}

		return this._instance;
	}

	public init() {
		// ensure that we have an instance of this
	}

	public logVital(system :string, identifier :string, from :number, to :number, time : number) {
		//
		this._log.push(new LogEntry('vital', {
			system: system,
			identifier: identifier,
			from: from,
			to: to,
			time: time
		}));
	}

	public logConnection(system :string, connected :boolean) {
		//
		if (connected) {
			this._log.push(new LogEntry('connected', system));
		} else {
			this._log.push(new LogEntry('disconnected', system));
		}
	}

	public logButton(button :string) {
		//
		console.log(`logButton: ${button}`);
		this._log.push(new LogEntry('btnPress', button));
	}

	public logView(view :string) {
		//
		this._log.push(new LogEntry('menuStateChangeEvent', view));
	}

	public logAlarm(system :string, identifier :string, value :number, threshold :number) {
		//
		this._log.push(new LogEntry('alarm', {
			system: system,
			identifier: identifier,
			value: value,
			threshold: threshold
		}));
	}

	public logAlarmAck(system :string, identifier :string, value :number, threshold :number) {
		//
		this._log.push(new LogEntry('alarmACK', {
			system: system,
			identifier: identifier,
			value: value,
			threshold: threshold
		}));
	}

	public logShock(successful :boolean) {
		//
		this._log.push(new LogEntry('shock', successful));
	}

	public getLog() : LogEntry[] {
		return this._log;
	}
}

export default LogService.getInstance();