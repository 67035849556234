export default class graphInstance {
    	moduleKey = null;
    	valueKey = null;
    	lead = null;
    	selector = null;
    	target = null;
    	jsonDataArray :any[] = [[0,-10]];
    	updateJsonDataArray :any[] = [];
    	jsonPointer = 0;
    	beep = function(){/**/};
		stim = function(){/**/};
    	isInverted = false;
		valueMultiCalculated = 0;
		emitPulse = function(){/**/};
		onPulse = false;
		onPulseDraw = false;
    	
    	connected = false;
    	
		ctx :any;
		
		ecgShockJSON = [0,0,0,0,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,1,0.8,0.5,0.3,0];

    	
    	_json :any= {
    		disconnected: [[0,-10]],
    		connected: null,
    		shock: null,
			afterDefib: null,
			artifact: [-0.4269972452,-0.4049586777,-0.3801652893,-0.3443526171,-0.305785124,-0.2644628099,-0.2314049587,-0.1983471074,-0.1763085399,-0.1460055096,-0.1184573003,-0.06887052342,0,0.04683195592,0.07988980716,0.09641873278,0.1129476584,0.1404958678,0.1873278237,0.2479338843,0.2975206612,0.3415977961,0.3856749311,0.4159779614,0.4380165289,0.4573002755,0.4600550964,0.4600550964,0.4600550964,0.4655647383,0.4738292011,0.476584022,0.4820936639,0.4628099174,0.4490358127,0.3829201102,0.2947658402,0.1900826446,0.07438016529,-0.02754820937,-0.1101928375,-0.1845730028,-0.2561983471,-0.3002754821,-0.3443526171,-0.3663911846,-0.3746556474,-0.3856749311,-0.4049586777,-0.4049586777,-0.4159779614]
    	};
    	
    	strokeStyleNormal :any = '#000000';
    	strokeStyleInverted = '#ffffff';
    	
    	fill = false;
    	fillStyleNormal = '#000';
    	fillStyleInverted = '#000';
    	
    	startPosition = 'middle';
    	lineWidth = 1;
    	
    	// drawing stuff
    	//valueMulti = function () {return 10;};
    	x = -1;
    	y = -1;
    	width = 0;
    	height = 0;
    	
    	// symbol
    	symbol = false;
    	symbolPositionY = 0;
    	symbolFunction = function (ctx :any, x :number, y :number, radius :number) {
    		ctx.beginPath();
            // pi * r^2 = 1/2 * s^2 * sin (pi / 3)  =>  s = r * sqrt(2 * pi / sin(pi / 3))
            const size = radius * Math.sqrt(2 * Math.PI / Math.sin(Math.PI / 3)) * 2;
            const height = size * Math.sin(Math.PI / 3);
            ctx.moveTo(x - size/2, y + height/2);
            ctx.lineTo(x + size/2, y + height/2);
            ctx.lineTo(x, y - height/2);
            ctx.lineTo(x - size/2, y + height/2);
            ctx.fill();
            ctx.stroke();
        };
        
        // pacer
        pacerFunction = function(ctx :any, x :number, y :number) {
        	// draw green line
        	ctx.beginPath();
        	const strokeColor = ctx.strokeStyle;
        	const fillColor = ctx.fillStyle;
        	
        	ctx.strokeStyle = '#00a000';
        	ctx.fillStyle = '#00a000';
        	ctx.lineWidth = 2;
        	ctx.lineTo(x, 30);
        	ctx.lineTo(x, -30);
        	ctx.fill();
        	ctx.stroke();
        	
        	// reset color
        	ctx.strokeStyle = strokeColor;
        	ctx.fillStyle = fillColor;
        	ctx.lineWidth = 1;
        	
        	// draw diamond
        	x -= 2;
        	y -= 6;
        	ctx.moveTo(x, y);
        	const width = 12;
        	const height = 12;
        	
            // top left edge
        	ctx.lineTo(x - width / 2, y + height / 2);
            
            // bottom left edge
        	ctx.lineTo(x, y + height);
            
            // bottom right edge
        	ctx.lineTo(x + width / 2, y + height / 2);
            
            // closing the path automatically creates
            // the top right edge
        	ctx.closePath();
        	ctx.fill();
        }
        
        // listener
        onShock = false;
    	
        constructor(target :any, jsonDataArray :any, options :any) {
        	/*
    		// jQuery reference
            this.target = target;
    		
            $(target).data('gi', this);
            
            if (typeof $(target).attr('id') === 'undefined') {
            	$(target).attr('id', uuid());
            }
            
            this.width = $(target).width();
            this.height = $(target).height();
            
            if (this.width == 0) {
            	// check if we have something in the options
            	if (typeof options['width'] !== 'undefined') {
            		this.width = options['width'];
            	}
            }
            
            if (this.height == 0) {
            	// check if we have something in the options
            	if (typeof options['height'] !== 'undefined') {
            		this.height = options['height'];
            	}
            }
    		
    		// add canvas tag
            if (target.find('canvas').length == 0) {
    			target.append('<canvas width="'+2*this.width+'" height="'+this.height+'" style="top: 0px; left: 30px;width:'+this.width+'px;height:'+this.height+'px;">');
    		}
    		//*/
    		
    		this.ctx = target;
            
    		// set json data
            //this.jsonDataArray = jsonDataArray;
            this.updateData(jsonDataArray);
            
            // get context
            //this.ctx = target.find('canvas').get(0).getContext('2d');

            
            // get data selector
            //this.selector = this.target.attr('data-chart-selector');

            // set options
            Object.keys(options).forEach((key) => {
				// oh good lord... this is dark magic using the force
				const keyz = key as keyof graphInstance;
                (this[keyz] as any) = options[key];
			});
			   
			this.valueMultiCalculated = this.height/2*-1 * 0.7;
            
            // start point
            switch(this.startPosition) {
            	case 'top': this.ctx.translate(0, 0); break;
            	case 'middle': this.ctx.translate(0, this.height/2); break;
            	case 'bottom': this.ctx.translate(0, this.height-5); break;
            }
            
            this.ctx.lineWidth = this.lineWidth;
            this.ctx.lineJoin = 'round';
            this.ctx.strokeStyle = this.strokeStyle();
			this.ctx.fillStyle = this.fillStyle();
			
			if (JSON.stringify(this.jsonDataArray) == JSON.stringify([0])) {
				this.ctx.setLineDash([1, 3]);
			} else {
				this.ctx.setLineDash([]);
			}
			
			if (this.lead == 'ecg') {
				for (let i = 0; i < 30; i++) {
					this.ecgShockJSON.push(0);
				}

				//add -10 control flag
				const tempArray :any[] = [];
				for (let i = 0; i < this.ecgShockJSON.length; i++) {
					tempArray.push([this.ecgShockJSON[i], -10]);
				}
				
				this.setJson('shock', tempArray);
			}
		}
        
        strokeStyle() {
    		if (this.isInverted) {
    			return this.strokeStyleInverted;
    		} else {
    			return this.strokeStyleNormal;
    		}
    	}
        
        fillStyle() {
    		if (this.isInverted) {
    			return this.fillStyleInverted;
    		} else {
    			return this.fillStyleNormal;
    		}
    	}
        
        inverted(inverted :boolean) {
        	this.isInverted = inverted;
        	
        	// update color
        	this.ctx.strokeStyle = this.strokeStyle();
        }

        updateData(arr :any[]) {
			if(arr == null) return;

        	if (arr.length == 1) {
        		// check if it is a legacy array
        		if (!Array.isArray(arr[0])) {
        			arr[0] = [0,-10];
        		}
        	}

        	arr.forEach(function(k,v) {
        	    if (!Array.isArray(v)) {
        	    	arr[k] = [v, -10];
        	    }
        	});
        	
        	// check current update backlog
        	if (this.updateJsonDataArray.length > 2) {
        		this.updateJsonDataArray.splice(2, this.updateJsonDataArray.length);
        	}
        	
            this.updateJsonDataArray.push(arr);
        }
        
        setJson(key :string, data :any) {
        	this._json[key] = data;
        	
        	// checks
        	if (this.connected && key == 'connected') {
        		this.updateData(this._json[key]);
        	}
        }
        
        setConnected(state :boolean) {
        	// pre check
        	if (state && this._json['connected'] == null) {
        		return;
        	}
        	
        	this.connected = state;
        	if (this.connected) {
        		this.updateData(this._json['connected']);
        		this.ctx.setLineDash([]);
        	} else {
        		this.updateData(this._json.disconnected);
        	}
        }
        
        _updateData() {
			this.jsonPointer = 0;
			this.onPulseDraw = false;

            if (this.updateJsonDataArray.length > 0) {
				this.jsonDataArray = this.updateJsonDataArray.shift();
                
                if (!this.connected) {
                	// only update if the update queue is empty
                	if (this.updateJsonDataArray.length == 0) {
                		this.ctx.setLineDash([1, 3]);
                	}
    			} else {
					this.ctx.setLineDash([]);
    			}
            }
        }
        
        shock(success :boolean) {
        	/*
        	 * _json = {
    		disconnected: [[0,-10]],
    		connected: null,
    		shock: null,
    		afterDefib: null
    	};
        	 */
        	if (!this.connected) {
        		return;
        	}
        	
        	if (this.onShock) {
        		// reset update array
        		this.updateJsonDataArray = [];
//        		console.log('shock: (should be empty) this.updateJsonDataArray', this.updateJsonDataArray);
        		
        		// add shock json
        		this.updateData(this._json.shock);
//        		console.log('shock: (should contain shock element) this.updateJsonDataArray', this.updateJsonDataArray);
        		
        		// reset pointer -> will trigger the update
        		this._updateData();
        		
        		if (success) {
        			// add after defib rhythm
        			this.updateData(this._json.afterDefib);
//        			console.log('shock: (should contain afterDefib element) this.updateJsonDataArray', this.updateJsonDataArray);
//        			console.log('shock: ',this.updateJsonDataArray[0] == this._json.afterDefib);
        		} else {
        			// add previous rhythm
        			this.updateData(this._json.connected);
        		}
        	} else {
        		if (this._json.afterDefib != null) {
        			if (success) {
            			// add after defib rhythm
            			this.updateData(this._json.afterDefib);
            		} else {
            			// add after previous rhythm
            			this.updateData(this._json.connected);
            		}
        		}
        	}
		}
		
		cprArtifact() {
        	if (!this.connected) {
        		return;
        	}
        	
        	if (this.onShock) {
        		// reset update array
        		this.updateJsonDataArray = [];
//        		console.log('shock: (should be empty) this.updateJsonDataArray', this.updateJsonDataArray);
        		
        		// add shock json
        		this.updateData(this._json.artifact);
//        		console.log('shock: (should contain shock element) this.updateJsonDataArray', this.updateJsonDataArray);
        		
        		// reset pointer -> will trigger the update
        		this._updateData();
        		
       			// add previous rhythm
       			this.updateData(this._json.connected);
        	}
        }
        
        _pointerReset() {
        	this.ctx.stroke();
    		this.ctx.beginPath();
    		this.x = -1;
    		this.y = -1;
    		
    		this.ctx.clearRect(0, -1*this.height, 10, 2*this.height);
		}
		
		changeStrokeColor(color :any) {
			this.strokeStyle = color;
		}
		
		getDataControlFlag(jsonPointer :number) {
			return this.jsonDataArray[jsonPointer][1];
		}
		
		getDataValue(jsonPointer :number) {
			if (typeof this.jsonDataArray[jsonPointer] == 'undefined') {
				console.log(this.jsonDataArray, this.jsonDataArray[jsonPointer]);
				return 0;
			}
			return this.jsonDataArray[jsonPointer][0];
		}

		requestDraw() {
			if (!this.onPulseDraw) {
				this.onPulseDraw = true;
			}
		}
        
        getNextData(walkingGraphPointer :number, pxPerms :number) {
        	// reset at the beginning of the graph
        	if (walkingGraphPointer == 0) {
        		this._pointerReset();
        	}
        	
        	// begin drawing path
        	this.ctx.beginPath();
        	
        	// draw connecting line from previous drawing
        	if (this.x > 0) {
        		this.ctx.lineTo(this.x, this.y);
        	}
        	
        	let symbolX = -1;
        	let pacerLine = false;
        	let pacerX = 0;
        	
        	let updatedInShockRange = false;
        	
        	for (let i = 0; i < pxPerms; i++) {
        		this.y = this.getDataValue(this.jsonPointer) * this.valueMultiCalculated;
				this.x = walkingGraphPointer + i;
				
				// draw only on demand
				if (this.onPulse) {
					if (this.onPulseDraw) {
						// okay we can draw
					} else {
						this.y = 0;
						this.jsonPointer = 0;
					}
				}
        		
        		// check if control marker (legacy)
        		if (this.getDataValue(this.jsonPointer) == 2) {
        			// set y value to 0 before drawing
        			this.y = 0;
        			pacerLine = true;
        			pacerX = this.x;
        		}
        		
        		// drawing
        		this.ctx.lineTo(this.x, this.y);
        		
        		// make some noise
        		if (this.getDataValue(this.jsonPointer) == 1) {
					this.beep();
					this.emitPulse();
        			symbolX = this.x;
        		}
        		
        		// check if in shock range
        		if (this.getDataValue(this.jsonPointer) == 1 && !updatedInShockRange) {
        			updatedInShockRange = true;
        			//inShockRange = true;
        		}
        		
        		// increment internal pointer
        		this.jsonPointer++;
        		
        		// reset the array pointer if we are at the end of it
        		if (this.jsonPointer >= this.jsonDataArray.length) {
        			// and check if we have a new array to use
        			this._updateData();
        			
        			// calculate value multi
        			//this.valueMultiCalculated = this.valueMulti();
        		}
        	}
        	
        	// shock range unset check
        	if (!updatedInShockRange) {
        		//inShockRange = false;
        	}
        	
        	// draw moving brick
    		this.ctx.clearRect(walkingGraphPointer+3, -1*this.height, 3*2+5, this.height*2);
        	
        	// stroke/draw stuff
       		if (this.fill && this.jsonDataArray.length > 1) {
       			this.ctx.lineTo(this.x, 200);
       	     	this.ctx.lineTo(walkingGraphPointer-1, 200);
       	     	this.ctx.closePath();
       	     	this.ctx.fill();
       		} else {
       			this.ctx.stroke();
       		}
       		
       		// draw pacer
       		if (pacerLine) {
    			// draw
    			this.pacerFunction(this.ctx, pacerX, this.height/2 - this.symbolPositionY);
    			
    			this.stim();
    		}
       		
       		if (this.symbol && symbolX > 0) {
        		let posY = 0;
        		switch(this.startPosition) {
        			case 'middle': posY = this.height/2 - this.symbolPositionY; break;
        		}
        		this.symbolFunction(this.ctx, symbolX-2, posY, 2);
        	}
        }
    }