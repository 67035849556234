
import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, 
  IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent,
  IonRow, IonCol, IonBackButton, IonButtons } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent,
    IonRow, IonCol,
    IonBackButton, IonButtons
  }
});
