import PatientService from './PatientService';
import ValueChanger from './ValueChanger';
import EventBus from './EventBus';

export class SocketClientService {
	private static _instance : SocketClientService;
	private io = (window as any).io;

	constructor() {
		// listen to updates
		this.io.socket.on('patient', (resp: any) => {
			console.log("Patient update: ", resp);
			this.parsePatient(resp.data);
		});
		
		this.io.socket.on('event', (resp: any) => {
			console.log("event update: ", resp);
			EventBus.emit(resp.data.event, resp.data.value);
			//this.parsePatient(resp.data);
		});
		
		this.io.socket.on('attachement', (data: any) => {
			console.log(data);
			EventBus.emit('attachement', data);
		});
		
		this.io.socket.get('/event', (data: any[]) => { console.log(`event data: ${data}`) });
	}

	public static getInstance() : SocketClientService {
		if (!this._instance) {
			this._instance = new SocketClientService();
		}

		return this._instance;
	}

	public syncPatient() {
		return new Promise((resolve, reject) => {
			this.io.socket.get('/patient', (data: any[]) => {
				try {
					//
					this.parsePatient(data[0], true);
				} finally {
					resolve(true);
				}
			});
		});
	}

	private parsePatient(data: { [x: string]: any; }, instant = false) {
		const keys = Object.keys(data);
		for(const k of keys) {
			const val = data[k];
			//console.log(`${k}: ${data[k]}`);

			if (k.includes('vc_')) {
				// ValueChanger.updateSetting();
				switch(k) {
					case 'vc_ecgValue':
						ValueChanger.updateSetting('ecg', 'hf', val);
					break;
					
					case 'vc_spo2Value':
						ValueChanger.updateSetting('spo2', 'value', val);
					break;
					
					case 'vc_spo2Pp':
						ValueChanger.updateSetting('spo2', 'pp', val);
					break;
					
					case 'vc_nibpDia':
						ValueChanger.updateSetting('nibp', 'dia', val);
					break;
					
					case 'vc_nibpSys':
						ValueChanger.updateSetting('nibp', 'sys', val);
					break;
					
					case 'vc_etco2Rf':
						ValueChanger.updateSetting('etco2', 'af', val);
					break;
					
					case 'vc_etco2Value':
						ValueChanger.updateSetting('etco2', 'value', val);
					break;
				}
			} else if (k.includes('Connected')) {
				// ValueChanger.updateSetting();
				const system = k.replace('Connected', '');
				
				if (val) {
					PatientService.connect(system);
				} else {
					PatientService.disconnect(system)
				}
			} else if (k.includes('Rhythm')) {
				if (k.includes('RhythmAfterDefib')) {
					const system = k.replace('RhythmAfterDefib', '');
					PatientService.updateRhythmAfter(system, val);
				} else {
					const system = k.replace('Rhythm', '');
					PatientService.updateRhythmBefore(system, val);
				}
			} else {
				PatientService.updateFromWebsocket(k, val, instant);
			}
		}
	}
}

export default SocketClientService.getInstance();