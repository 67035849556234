import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import es from './es.json';
import ja from './ja.json';
import ko from './ko.json';
import id from './id.json';
import zh from './zh.json';
//https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n


export const translation = { en, de, fr, es, ja, ko, id, zh };