export class PatientModel {
	public connector :any = {
		'ecg': false,
		'spo2': false,
		'nibp': true, // let's assume NIBP is always connected
		'etco2': false,
		'ibp': false,
	};

	

	public patient :any = {
			/*
			 * ecg needs to be modeled to reflect 3, 12-channel ECG
			 */
		'ecg': {
			hf: {before: 80, after: 30, display: '--'},
			rhythm: {before: 1, after: 1},
		},
		'spo2': {
			value: {before: 98, after: 88, display: '--'},
			pp: {before: 80, after: 30, display: '--'},
			rhythm: {before: 1, after: 1},
		},
		'nibp': {
			sys: {before: 130, after: 80, display: '--'},
			dia: {before: 80, after: 30, display: '--'},
			combined: {display: '--/--'},
		},
		'etco2': {
			value: {before: 28, after: 22, display: '--'},
			af: {before: 12, after: 10, display: '--'},
			rhythm: {before: 1, after: 1},
		},
		'ibp': {
			rhythm: {before: 0, after: 0},
		},
	};
	
	/*
	constructor () {
	}*/
	
	public getPatient() {
		return this.patient;
	}

	public getSystem(system : string) {
		return this.patient[system];
	}
	
	public getConnector() {
		return this.connector;
	}
}