
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	modalController,
} from '@ionic/vue';
import { defineComponent } from 'vue';

import DatabaseService from '../../services/DatabaseService';

export default defineComponent({
	name: 'AttachementModal',
	components: { IonContent,IonHeader,IonTitle,IonToolbar,IonButtons,IonButton },
	props: ['attachement'],
	methods: {
		close() {
			return modalController.dismiss(null,'cancel');
		},
		imgsrc() {
			return `${DatabaseService.getBaseURL()}${this.attachement.link}`;
		}
	},
});
