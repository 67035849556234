
import { defineComponent } from "vue";
import { loadingController } from "@ionic/vue";

import DatabaseService from '../../services/DatabaseService';

let ecgWorkerJobs : Map<string, boolean> = new Map<string, boolean>();

export default defineComponent({
	name: "SetupLoading",
	data() {
		return {
			checkedFinishedIdx: setInterval.prototype,
			worker: Worker.prototype,
			workerSpo2: Worker.prototype,
			workerEtco2: Worker.prototype,
			clientWidth: 0,
			widthCheckIDX: setInterval.prototype,
			finishedJobs: 0,
			completedRows: 0,
			totalRows: 0,
			importingDatabase: true,
			importingDatabaseSuccess: false
		}
	},
	mounted() {
		// eslint-disable-next-line
		let that = this;

		(window as any).setup = this;

		this.widthCheckIDX = setInterval(() => {
			let width = (this.$refs.widthGetDiv as HTMLDivElement).clientWidth;
			
			if (width > 0) {
				that.clientWidth = width;
				clearInterval(that.widthCheckIDX);
				that.preCheck();
			}
		}, 1000);
		
		this.worker = new Worker('/assets/worker/worker.js');
		this.worker.onmessage = function(response) {
	    	// postMessage({lead: lead, result: result});
//			console.log(response);
			var e = {data: JSON.parse(response.data)};
	    	var lead = e.data.lead;
	    	var result = e.data.result;

	    	that.processWorkerResult(lead, e.data.mode, e.data.id, e.data.value, result);
	    };
	    
	    this.workerSpo2 = new Worker('/assets/worker/workerSpo2.js');
		this.workerSpo2.onmessage = function(response) {
			var e = {data: JSON.parse(response.data)};
	    	var lead = e.data.lead;
	    	var result = e.data.result;
	    	
	    	that.processWorkerResult(lead, e.data.mode, e.data.id, e.data.value, result);
	    };
	    
	    this.workerEtco2 = new Worker('/assets/worker/workerEtco2.js');
		this.workerEtco2.onmessage = function(response) {
			var e = {data: JSON.parse(response.data)};
	    	var lead = e.data.lead;
	    	var result = e.data.result;
	    	
	    	that.processWorkerResult(lead, e.data.mode, e.data.id, e.data.value, result);
		};

		//this.presentLoading();
	},
	methods: {
		preCheck: function(forceCaluclation = false) {
			// eslint-disable-next-line
			let that = this;

			DatabaseService.table('ecg').count().then(length => {
				console.log(`the database 'ecg' has ${length} records.`);
				
				if (length == 0) {
					that.presentLoading();
					setTimeout(() =>{that.createDatabase(forceCaluclation)}, 4000);
				}
			}).catch(error => {
				console.error(error);
			});
		},
		presentLoading: async function() {
			// eslint-disable-next-line
			var that = this;

			const loading = await loadingController.create({
				message: this.$t('setup.messages.general'),//"Initialisierung l&auml;uft...<br />Dies dauert bis zu 5 Minuten.",
				backdropDismiss: false,
				translucent: true,
			});

			//*
			this.checkedFinishedIdx = setInterval(() => {
				if (this.importingDatabaseSuccess || that.finishedJobs == ecgWorkerJobs.size && ecgWorkerJobs.size > 0) {
					clearInterval(this.checkedFinishedIdx);
					loading.dismiss();
				} else {
					if (this.importingDatabase) {
						loading.message = `${this.$t('setup.messages.import')}(${this.completedRows}/${this.totalRows})`;
					} else {
						loading.message = `${this.$t('setup.messages.calculating')}(${that.finishedJobs}/${ecgWorkerJobs.size})`;
					}
				}
				/*
				let jobs = Object.values(ecgWorkerJobs);

				if (jobs.length > 0 && jobs.every(value => {return value;})) {
					console.log("woop woop");
					clearInterval(this.checkedFinishedIdx);

					loading.dismiss();
				}//*/
			}, 2000);
			//*/
			
			await loading.present();

			//setTimeout(function() {loading.dismiss();}, 5000);
		},
		clearDatabase: function() {
			console.log("Clearing database");
    		DatabaseService.clearDatabase();
		},
		exportDatabase: function() {
			DatabaseService.exportDB().then(blob => {
				console.log('Database exported');
				console.log(blob);

				let url = window.URL.createObjectURL(blob);
				console.log(`Download link: ${url}`);
			}).catch(err => {
				console.error(err);
			});
		},
		createDatabase: async function(skipImport = false) {
			// eslint-disable-next-line
			var that = this;

			// let's try importing first
			if (!skipImport) {
				try {
					let importResult = await DatabaseService.manualJsonImport('/data/stl_v1.json', (totalRows :number, completedRows: number) => {
						this.completedRows = completedRows;
						this.totalRows = totalRows;

						//console.log(`Progress: ${completedRows} of ${totalRows} rows completed`);
					});

					if (importResult === true) {
						this.importingDatabaseSuccess = true;
						return;
					} else {
						console.log(importResult);
						this.importingDatabase = false;
					}
				} catch(e) {
					this.importingDatabase = false;
				}
			}
			
			// screensize
			let size = this.clientWidth;
			
			// we need 80% of that
			size = Math.floor(size * 0.8);

			let graphData :any[] = [];
			
			let _parentData = DatabaseService.getGraphs('ecg');
			let _rawEcgs = DatabaseService.getAllEcgRaw();
			for (let _raw of _rawEcgs) {
				var _sel = [
					'ecgI','ecgII','ecgIII',
					'ecgaVR','ecgaVL','ecgaVF',
					'ecgV1','ecgV2','ecgV3','ecgV4','ecgV5','ecgV6'];

				graphData = [];
				_sel.forEach(function (k, v) {
					graphData.push({
						id: v,
						width: size,
						jData: JSON.parse(_raw[k]),
						maxInterpolation: 3
					});
				});
				
				let _ecgData = _parentData.find(x => x.id === _raw.type);
				for (let i = 1; i <= 300; i++) {
					// create worker data
					let workerData = {
						lead: 'ECG',
						type: _raw.type,
						value: i,
						mode: 'current',
						data: _ecgData,
						graphData: graphData

					};
					
					ecgWorkerJobs.set(_raw.type+'_'+i, false);
					this.worker.postMessage(JSON.stringify(workerData));
				}
			}

			let _rawSpo2s = DatabaseService.getAllSpo2Raw();
			for (let _raw of _rawSpo2s) {
				graphData = [];
				graphData.push({
					id: 'none',
					width: size,
					jData: JSON.parse(_raw.pleth),
					maxInterpolation: 4
				});

				for (let i = 1; i <= 300; i++) {
					// create worker data
					let workerData = {
						lead: 'Spo2',
						type: _raw.type,
						value: i,
						mode: 'current',
						data: _raw,
						graphData: graphData
					};
					
					ecgWorkerJobs.set('spo2_'+_raw.id+'_'+i, false);
					this.workerSpo2.postMessage(JSON.stringify(workerData));
				}
			}

			let _rawEtco2s = DatabaseService.getAllEtco2Raw();
			for (let _raw of _rawEtco2s) {
				graphData = [];
				graphData.push({
					id: 'none',
					width: size,
					jData: JSON.parse(_raw.capnogram),
					maxInterpolation: 4
				});

				for (let i = 1; i <= 50; i++) {
					// create worker data
					let workerData = {
						lead: 'CO2',
						type: _raw.id,
						value: i,
						mode: 'current',
						data: _raw,
						graphData: graphData
					};
					
					ecgWorkerJobs.set('etco2_'+_raw.id+'_'+i, false);
					this.workerEtco2.postMessage(JSON.stringify(workerData));
				}
			}

			console.log(`Calculating ${ecgWorkerJobs.size} Graphs.`);
		},
		processWorkerResult: function(lead :string, mode :string, id :number, value :number, result :any) {
	//		console.log(lead, mode, id, value, result);
	//		console.log(`checking... ${Object.values(ecgWorkerJobs).length} ${Object.values(ecgWorkerJobs).every((value) => {return value;})}`);
			
			// eslint-disable-next-line
			let that = this;

			if (lead == 'ECG') {
				let data = {
					parentid: id,
					heartrate: value,
					ecgI: result[0],
					ecgII: result[1],
					ecgIII: result[2],
					ecgaVR: result[3],
					ecgaVL: result[4],
					ecgaVF: result[5],
					ecgV1: result[6],
					ecgV2: result[7],
					ecgV3: result[8],
					ecgV4: result[9],
					ecgV5: result[10],
					ecgV6: result[11],
				};

				DatabaseService.table('ecg').add(data).then(result => {
					// Success
					ecgWorkerJobs.set(id+'_'+value, true);
					that.finishedJobs++;
				}).catch('ConstraintError', e => {
					// this might happen due to double unique keys and can be disregarded (well actually we should check why we came here then in the first place)
					// Failed with ConstraintError
					console.error ("Constraint error: " + e.message);
				}).catch(Error, e => {
					// Any other error derived from standard Error
					console.error ("Error: " + e.message);
				}).catch(e => {
					// Other error such as a string was thrown
					console.error (e);
				});
			} else if (lead == 'Spo2') {
				let data = {
						parentid: id,
						heartrate: value,
						graph: result.none,
					};
				
				DatabaseService.table('spo2').add(data).then(result => {
					// Success
					ecgWorkerJobs.set('spo2_'+id+'_'+value, true);
					that.finishedJobs++;
				}).catch('ConstraintError', e => {
					// this might happen due to double unique keys and can be disregarded (well actually we should check why we came here then in the first place)
					// Failed with ConstraintError
					console.error ("Constraint error: " + e.message);
				}).catch(Error, e => {
					// Any other error derived from standard Error
					console.error ("Error: " + e.message);
				}).catch(e => {
					// Other error such as a string was thrown
					console.error (e);
				});
			} else if (lead == 'CO2') {
				let data = {
						parentid: id,
						heartrate: value,
						graph: result.none,
					};
				
				DatabaseService.table('etco2').add(data).then(result => {
					// Success
					ecgWorkerJobs.set('etco2_'+id+'_'+value, true);
					that.finishedJobs++;
				}).catch('ConstraintError', e => {
					// this might happen due to double unique keys and can be disregarded (well actually we should check why we came here then in the first place)
					// Failed with ConstraintError
					console.error ("Constraint error: " + e.message);
				}).catch(Error, e => {
					// Any other error derived from standard Error
					console.error ("Error: " + e.message);
				}).catch(e => {
					// Other error such as a string was thrown
					console.error (e);
				});
			}
		}
	}
});
