
import { defineComponent } from 'vue';
import { IonPopover, IonIcon, IonList, IonItem } from '@ionic/vue';
import { addCircleOutline, removeCircleOutline } from 'ionicons/icons';

export default defineComponent({
	name: 'MonitorVital',
	components: {
		IonPopover, IonIcon, IonList, IonItem
	},
	props: {
		title: String,
		unit: String,
		alarmUpperInit: {type: String, required: true},
		alarmLowerInit: {type: String, required: true},
		displayValue: {type: String, required: true},
		max: {type: Object, required: true},
		step: {type: Number, required: true},
	},
	data() {
		return {
			alarmUpper: (this.alarmUpperInit != '') ? parseInt(this.alarmUpperInit) : -1,
			alarmLower: parseInt(this.alarmLowerInit),
		}
	},
	setup() {
		return {
			addCircleOutline, removeCircleOutline
		};
	},

	computed: {
		hasAlarm() {
			// nibp special
			if (this.displayValue.includes('/') && !this.displayValue.includes('--')) {
				let rr = this.displayValue.split('/');

				// check upper alarm threshold
				if (this.alarmUpperInit != '') {
					if (this.alarmUpper < parseInt(rr[0])) {
						return true;
					}
				}

				// check lower threshold
				if (this.alarmLowerInit != '') {
					if (this.alarmLower > parseInt(rr[1])) {
						return true;
					}
				}
			} else if (!this.displayValue.includes('--')) {
				// check upper alarm threshold
				if (this.alarmUpperInit != '') {
					if (this.alarmUpper < parseInt(this.displayValue)) {
						return true;
					}
				}

				// check lower threshold
				if (this.alarmLowerInit != '') {
					if (this.alarmLower > parseInt(this.displayValue)) {
						return true;
					}
				}
			}

			return false;
		}
	},

	methods: {
		add: function(up :boolean) {
			if (up) {
				this.alarmUpper += this.step;

				if (this.alarmUpper > this.max.upper) {
					this.alarmUpper = this.max.upper;
				}
			} else {
				this.alarmLower += this.step;

				if (this.alarmLower > this.max.lower) {
					this.alarmLower = this.max.lower;
				}
			}
		},
		sub: function(up :boolean) {
			if (up) {
				this.alarmUpper -= this.step;
			} else {
				this.alarmLower -= this.step;

				if (this.alarmLower < 0) {
					this.alarmLower = 0;
				}
			}
		}
	}
});
