
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/vue';

import EventBus from '../../../services/EventBus';
import PatientService from '../../../services/PatientService';
import SoundManager from '../SoundManager';

export default defineComponent({
	name: 'MonitorNibp',
	data() {
		return {
			active: false,
			nibpSys: '--',
			nibpDia: '--',
			measuring: false,
			idxMeasuring: -1,
		}
	},
	components: {
		IonGrid, IonRow, IonCol, IonButton
	},
	mounted() {
		// eslint-disable-next-line
		let that = this;

		EventBus.addEventListener('menuStateChangeEvent', (event) => {
			let state = EventBus.getData(event);

			if (state != 'nibp') {
				// reset vars
				this.active = false;
				/*
				this.measuring = false;
				this.nibpSys = '--';
				this.nibpDia = '--';
				*/
				//clearTimeout(that.idxMeasuring);
			} else {
				this.active = true;
			}
    	});
    	
    	EventBus.addEventListener('keypressed', (event) => {
			let data = EventBus.getData(event);

			if (data === 'manualnibp') {
				//
				PatientService.updateDisplayValue('nibp', 'sys');
				PatientService.updateDisplayValue('nibp', 'dia');
			}
    	});
	},
	methods: {
		nibpStart: function() {
			// eslint-disable-next-line
			let that = this;

			this.measuring = true;
			SoundManager.play('nibp');

			setTimeout(() => {
				SoundManager.stop('nibp');
			}, 8000);

			EventBus.emit('btnPress', 'nibp');

			this.idxMeasuring = setTimeout(() => {
				this.measuring = false;

				PatientService.updateDisplayValue('nibp', 'sys');
				PatientService.updateDisplayValue('nibp', 'dia');
				
				this.nibpSys = PatientService.getDisplayValue('nibp', 'sys');
				this.nibpDia = PatientService.getDisplayValue('nibp', 'dia');
			}, 16500);
		}
	}
});
