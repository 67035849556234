import EventBus from '../../services/EventBus';
import DatabaseService from '../../services/DatabaseService';
import PatientService from '../../services/PatientService';

export default class HeartBeat {
	onShock = true;
	connected = true;
	jsonPointer = 0;
	jsonDataArray: any[] = [[0, -10]];
	updateJsonDataArray: any[] = [];

	ecgShockJSON = [0, 0, 0, 0, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 2.2, 1, 0.8, 0.5, 0.3, 0];

	_json: any = {
		disconnected: [[0, -10]],
		connected: null,
		shock: null,
		afterDefib: null,
		artifact: [-0.4269972452, -0.4049586777, -0.3801652893, -0.3443526171, -0.305785124, -0.2644628099, -0.2314049587, -0.1983471074, -0.1763085399, -0.1460055096, -0.1184573003, -0.06887052342, 0, 0.04683195592, 0.07988980716, 0.09641873278, 0.1129476584, 0.1404958678, 0.1873278237, 0.2479338843, 0.2975206612, 0.3415977961, 0.3856749311, 0.4159779614, 0.4380165289, 0.4573002755, 0.4600550964, 0.4600550964, 0.4600550964, 0.4655647383, 0.4738292011, 0.476584022, 0.4820936639, 0.4628099174, 0.4490358127, 0.3829201102, 0.2947658402, 0.1900826446, 0.07438016529, -0.02754820937, -0.1101928375, -0.1845730028, -0.2561983471, -0.3002754821, -0.3443526171, -0.3663911846, -0.3746556474, -0.3856749311, -0.4049586777, -0.4049586777, -0.4159779614]
	};

	emitPulse = () => {EventBus.emit('pulse', null);};

	emitPulseFrequency = 60000;
	emitPulseCounter = 0;

	constructor() {
		for (let i = 0; i < 30; i++) {
			this.ecgShockJSON.push(0);
		}

		//add -10 control flag
		const tempArray: any[] = [];
		for (let i = 0; i < this.ecgShockJSON.length; i++) {
			tempArray.push([this.ecgShockJSON[i], -10]);
		}

		this.setJson('shock', tempArray);

		EventBus.addEventListener('patientSystemIsConnected', (event) => {
			const data = EventBus.getData(event);

			if (data.system == 'ecg') {
				//
				this.connected = !data.value;
				console.log(`Setting Hearbeat(${data.system}) to ${!data.value}`);
			}
		});

		EventBus.addEventListener('updateValue', (event) => {
			const data = EventBus.getData(event);

			const ident = data.system;
			let value = data.value;

			if (data.system == 'ecg') {
				if (data.system == 'ecg' && data.identifier == 'hf') {
					value = data.value;

					this.emitPulseFrequency = (60/value)*1000;
					console.log(`Heartbeat: ${value} - ${this.emitPulseFrequency}`);
				}

				const id = PatientService.getRhythmBefore(ident);
				DatabaseService.table(ident).where({parentid: ''+id, heartrate: value}).first().then(d => {
					let json;
					if (ident == 'ecg') {
						json = d.ecgII;
					}

					this.setJson('connected', json);
				});
			}
		});

		EventBus.addEventListener('shocked', () => {
			// zaaaaap!
			this.shock(true);
		});

		// EventBus.emit('walkingGraph', {pointer: walkingGraphPointer, pxPerms: pxPerms});
		EventBus.addEventListener('walkingGraph', (event) => {
			const data = EventBus.getData(event);

			this.getNextData(data?.pointer, data.pxPerms);
		});

		// start
		this.updateData(this._json['connected']);
	}

	updateData(arr: any[]) {
		if (arr == null) return;

		if (arr.length == 1) {
			// check if it is a legacy array
			if (!Array.isArray(arr[0])) {
				arr[0] = [0, -10];
			}
		}

		arr.forEach(function (k, v) {
			if (!Array.isArray(v)) {
				arr[k] = [v, -10];
			}
		});

		// check current update backlog
		if (this.updateJsonDataArray.length > 2) {
			this.updateJsonDataArray.splice(2, this.updateJsonDataArray.length);
		}

		this.updateJsonDataArray.push(arr);
	}

	setJson(key: string, data: any) {
		this._json[key] = data;

		this.updateData(this._json[key]);
	}

	_updateData() {
		this.jsonPointer = 0;

		if (this.updateJsonDataArray.length > 0) {
			this.jsonDataArray = this.updateJsonDataArray.shift();
		}
	}

	shock(success: boolean) {
		if (this.onShock) {
			// reset update array
			this.updateJsonDataArray = [];

			// add shock json
			this.updateData(this._json.shock);

			// reset pointer -> will trigger the update
			this._updateData();

			if (success) {
				// add after defib rhythm
				this.updateData(this._json.afterDefib);
				//        			console.log('shock: (should contain afterDefib element) this.updateJsonDataArray', this.updateJsonDataArray);
				//        			console.log('shock: ',this.updateJsonDataArray[0] == this._json.afterDefib);
			} else {
				// add previous rhythm
				this.updateData(this._json.connected);
			}
		} else {
			if (this._json.afterDefib != null) {
				if (success) {
					// add after defib rhythm
					this.updateData(this._json.afterDefib);
				} else {
					// add after previous rhythm
					this.updateData(this._json.connected);
				}
			}
		}
	}

	getDataValue(jsonPointer: number) {
		if (typeof this.jsonDataArray[jsonPointer] == 'undefined') {
			console.log(this.jsonDataArray, this.jsonDataArray[jsonPointer]);
			return 0;
		}
		return this.jsonDataArray[jsonPointer][0];
	}

	getNextData(walkingGraphPointer: number, pxPerms: number) {
		/*
		let updatedInShockRange = false;

		for (let i = 0; i < pxPerms; i++) {
			// check if control marker (legacy)
			if (this.getDataValue(this.jsonPointer) == 2) {
				// set y value to 0 before drawing
				//pacerLine = true;
			}

			// make some noise
			if (this.getDataValue(this.jsonPointer) == 1) {
				//this.beep();
				this.emitPulse();
			}

			// check if in shock range
			if (this.getDataValue(this.jsonPointer) == 1 && !updatedInShockRange) {
				updatedInShockRange = true;
				//inShockRange = true;
			}

			// increment internal pointer
			this.jsonPointer++;

			// reset the array pointer if we are at the end of it
			if (this.jsonPointer >= this.jsonDataArray.length) {
				// and check if we have a new array to use
				this._updateData();

			}
		}
		//*/
		this.emitPulseCounter += 20; // this fixed number needs to reflect the walkingGraph interval
		
		if (this.emitPulseCounter >= this.emitPulseFrequency) {
			this.emitPulse();

			this.emitPulseCounter = 0;
		}
	}
}