class SoundManager {
	private static _instance: SoundManager;

	private sounds = new Map<string, HTMLAudioElement>();

	constructor() {
		this.sounds.set('charge', new Audio('/assets/sounds/charge.wav'));

		const nibp = new Audio('/assets/sounds/nibp.wav');
		nibp.volume = 0.2;
		this.sounds.set('nibp', new Audio('/assets/sounds/nibp.wav'));
		this.sounds.set('shock', new Audio('/assets/sounds/shock.wav'));
		this.sounds.set('shocktone', new Audio('/assets/sounds/shocktone.wav'));

		this.sounds.set('pleth100', new Audio('/assets/sounds/pleth100.wav'));
		this.sounds.set('pleth97', new Audio('/assets/sounds/pleth97.wav'));
		this.sounds.set('pleth94', new Audio('/assets/sounds/pleth94.wav'));
		this.sounds.set('pleth90', new Audio('/assets/sounds/pleth90.wav'));
		this.sounds.set('pleth85', new Audio('/assets/sounds/pleth85.wav'));
		this.sounds.set('pleth80', new Audio('/assets/sounds/pleth80.wav'));
		this.sounds.set('pleth75', new Audio('/assets/sounds/pleth75.wav'));
		this.sounds.set('pleth72', new Audio('/assets/sounds/pleth72.wav'));
	}

	public static getInstance() {
		if (this._instance == null) {
			this._instance = new SoundManager();
		}

		return this._instance;
	}

	play(sound :string) {
		if (this.sounds.has(sound)) {
			this.sounds.get(sound)?.play();
		}
	}

	stop(sound :string) {
		const media = this.sounds.get(sound);
		if (typeof media !== 'undefined') {
			media.pause();
			media.currentTime = 0;
		}
	}

	playPleth(spo2 :number) {
		if (spo2 <= 72) {
			spo2 = 72;
		} else if (spo2 <= 75) {
			spo2 = 75;
		} else if (spo2 <= 80) {
			spo2 = 80;
		} else if (spo2 <= 85) {
			spo2 = 85;
		} else if (spo2 <= 90) {
			spo2 = 90;
		} else if (spo2 <= 94) {
			spo2 = 94;
		} else if (spo2 <= 97) {
			spo2 = 97;
		} else {
			spo2 = 100;
		}
		
		this.play(`pleth${spo2}`);
	}
}

export default SoundManager.getInstance();