
import { defineComponent } from 'vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';

import EventBus from '../../../services/EventBus';

export default defineComponent({
	name: 'MonitorDefi',
	data() {
		return {
			active: false,
			messagePointer: 0,
			energy: 200,

			charging: false,
			charged: false,
			idxCharge: setTimeout.prototype,
			idxCharged: setTimeout.prototype,
			idxTimer: setTimeout.prototype,
			
			timerString: '00:00',
			timerLastString: '00:00',
			stopwatchTimer: 0,
			lastShockTimer: -1,
			shockCounter: 0,
		}
	},
	components: {
		IonGrid, IonRow, IonCol
	},
	mounted() {
		// eslint-disable-next-line
		let that = this;

		EventBus.addEventListener('menuStateChangeEvent', (event) => {
			let state = EventBus.getData(event);

			if (state != 'defib') {
				if (!this.active) return;
				// reset vars
				that.active = false;
				that.messagePointer = 0;
				that.shockCounter = 0;
				that.stopwatchTimer = 0;
				this.lastShockTimer = -1;
				that.timerString = '00:00';
				this.timerLastString = '00:00';

				that.charging = false;
				that.charged = false;
				clearTimeout(that.idxCharge);
				clearTimeout(that.idxCharged);
				clearTimeout(that.idxTimer);

				EventBus.emit('defibDischarged', true);
			} else {
				that.active = true;

				this.idxTimer = setInterval(() => {
					this.stopwatchTimer++;

					if (this.lastShockTimer >= 0) {
						this.lastShockTimer++;
					}

					this.timerString = this.prettyTimer(this.stopwatchTimer);
					this.timerLastString = this.prettyTimer(this.lastShockTimer);
				}, 1000);
			}
    	});
	
		EventBus.addEventListener('btnPress', (event) => {
			let btnPress = EventBus.getData(event);
			if (!that.active) {
				return;
			}

			if (btnPress == 'energyUp' && !that.charging) {
				that.energyUp();
			} else if (btnPress == 'energyDn' && !that.charging) {
				that.energyDn();
			} else if (btnPress == 'charge' && !that.charging) {
				that.charge();
			} else if (btnPress == 'shock' && that.charged) {
				that.shock();
				this.lastShockTimer = 0;
			}
		});
	},
	methods: {
		prettyTimer: function(time :number) {
			if (time <= 0) {
				return '00:00';
			}

			let minutes = Math.floor(time / 60);
			let minutesString = '00';
			if (minutes < 10) {
				minutesString = '0' + minutes;
			} else {
				minutesString = '' + minutes;
			}

			let seconds = time % 60;
			let secondsString = '00';
			if (seconds < 10) {
				secondsString = '0' + seconds;
			} else {
				secondsString = '' + seconds;
			}

			return `${minutesString}:${secondsString}`;
		},
		energyUp: function() {
			let newEnergy = this.energy + 10;

			if (newEnergy > 200) {
				newEnergy = 200;
			}

			this.energy = newEnergy;
		},
		energyDn: function() {
			let newEnergy = this.energy - 10;

			if (newEnergy <= 0) {
				newEnergy = 0;
			}

			this.energy = newEnergy;
		},
		charge: function() {
			// eslint-disable-next-line
			let that = this;

			this.messagePointer = 1;

			this.charging = true;

			that.idxCharged = setTimeout(() => {
				that.charged = true;
				that.messagePointer = 2;
				EventBus.emit('defibCharged', true);
			}, 4500);
			
		},
		shock: function() {
			this.messagePointer = 4;

			this.charging = false;
			this.charged = false;

			this.shockCounter++;

			EventBus.emit('shocked', true);
		}
	}
});
