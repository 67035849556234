import EventBus from '../../../services/EventBus';
import PatientService from '../../../services/PatientService';
import SoundManager from '../SoundManager';

export default class PlethTone {
	private isEcgConnected = false;
	private isSpo2Connected = false;
	private spo2Value = 0;

	constructor() {
		this.isEcgConnected = PatientService.isConnected('ecg');
		this.isSpo2Connected = PatientService.isConnected('spo2');
		this.spo2Value = PatientService.getValue('spo2', 'value', 'before');

		EventBus.addEventListener('patientSystemIsConnected', (event) => {
			const data = EventBus.getData(event);

			if (data.system == 'ecg') {
				this.isEcgConnected = data.value;
			} else if (data.system == 'spo2') {
				this.isSpo2Connected = data.value;
			}
		});

		EventBus.addEventListener('updateValue', (event) => {
			const data = EventBus.getData(event);
			
			if (data.system == 'spo2' && data.identifier == 'value') {
				this.spo2Value = data.value;
			}
		});

		EventBus.addEventListener('pulse', () => {
			// make some beep
			if (this.isEcgConnected && !this.isSpo2Connected) {
				SoundManager.playPleth(100);
			} else if (this.isEcgConnected || this.isSpo2Connected) {
				SoundManager.playPleth(this.spo2Value);
			}
		});
	}
}