
import { defineComponent } from 'vue';
import { IonIcon } from '@ionic/vue';
import { caretUpOutline, caretDownOutline, flashOutline } from 'ionicons/icons';

import EventBus from '../../services/EventBus';

export default defineComponent({
	name: 'MonitorMenuComponent',
	props: {
		state: String,
		ecgmenu: Boolean
	},
	data() {
		return {
			charged: false,
		}
	},
 	
	components: {IonIcon,},
	setup() {
		return {
			caretUpOutline, caretDownOutline, flashOutline
		};
	},
	mounted() {
		EventBus.addEventListener('defibCharged', (event) => {
			this.charged = true;
		});

		EventBus.addEventListener('defibDischarged', (event) => {
			this.charged = false;
		});

		EventBus.addEventListener('shocked', (event) => {
			this.charged = false;
		});
	},
	methods: {
		menuChange: (btn:string, state:string) => {
			if (state != 'monitor' && btn == '12ch') {
				// we go back to monitor first
				btn = 'monitor';
			}

			EventBus.emit('menuStateChangeEvent', btn);
		},
		btnPress: (btn:string) => {
			EventBus.emit('btnPress', btn);
		}
	},
});
