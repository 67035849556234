
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
  IonRow, IonCol,
  IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle,
  IonFab, IonFabButton, IonIcon,
  IonFooter,
  IonMenu, IonList, IonListHeader, IonItem,
  IonLabel, IonToggle, IonInput,
  IonSelect, IonSelectOption,
  menuController
} from '@ionic/vue';
import { defineComponent } from 'vue';

import { settingsOutline } from 'ionicons/icons';

import SetupLoading from "./components/SetupLoading.vue";
import DatabaseService from '../services/DatabaseService';

export default defineComponent({
  components: {
    IonContent,
    IonHeader, IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow, IonCol,
    IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle,
    IonFab, IonFabButton, IonIcon,
    IonMenu, IonList, IonListHeader, IonItem,
    IonSelect, IonSelectOption,
    IonLabel, IonToggle, IonInput,
    SetupLoading
  },
  data() {
    /*
    {key: 'darkmode', value: 'true'},
							{key: 'ecgmenu', value: 'true'},
							{key: 'devicename', value: 'STL Dev'},
							{key: 'btactive', value: 'false'},
							{key: 'wifiactive', value: 'true'},
              {key: 'password', value: '123456'},
    */
    return {
      darkmode: true,
      ecgmenu: true,
      devicename: '',
      btactive: false,
      wifiactive: false,
      password: ''
    }
  },
  mounted() {
    menuController.enable(true, 'settings');
    DatabaseService.getValue('settings', 'darkmode').then(r => {this.darkmode = r.value;});
    DatabaseService.getValue('settings', 'ecgmenu').then(r => {this.ecgmenu = r.value;});
    DatabaseService.getValue('settings', 'btactive').then(r => {this.btactive = r.value;});
    DatabaseService.getValue('settings', 'wifiactive').then(r => {this.wifiactive = r.value;});

    DatabaseService.getValue('settings', 'devicename').then(r => {this.devicename = r.value;});
    DatabaseService.getValue('settings', 'password').then(r => {this.password = r.value;});
  },
  setup() {
    return {
      settingsOutline,
    };
  },
  methods: {
	openMenu() {
		menuController.open('settings');
    },
    saveMenu() {
      DatabaseService.setValue('settings', 'darkmode', this.darkmode.toString());
      DatabaseService.setValue('settings', 'ecgmenu', this.ecgmenu.toString());
      DatabaseService.setValue('settings', 'btactive', this.btactive.toString());
      DatabaseService.setValue('settings', 'wifiactive', this.wifiactive.toString());

      DatabaseService.setValue('settings', 'devicename', this.devicename);
      DatabaseService.setValue('settings', 'password', this.password);
    },
    localeChange(event:any) {
      this.$i18n.locale = event.detail.value;
      localStorage.setItem('locale', event.detail.value);
    }
  }
});
