<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title color="dark">{{$t('connectionModal.title')}}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-slides pager="true">
        <ion-slide>
          <ion-card>
            <ion-card-header>
              <ion-card-title>{{$t('connectionModal.wifi.title')}}</ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <qrcode-vue :value=generateWebLink :size="size" level="H" style="padding-top:20px" />
              <p>{{$t('connectionModal.wifi.subtitle')}}</p>
              <br/>
              <h2>IP: {{ip}}</h2>
              <h2>ID: {{webId}}</h2>
              <h2>{{$t('connectionModal.wifi.password')}}: {{webPassword}}</h2>
              <br/>
              <p style="display: none;">{{ statusMessage }}</p>
            </ion-card-content>
          </ion-card>
        </ion-slide>
      </ion-slides>

      <ion-button v-on:click="closeMe">{{$t('connectionModal.wifi.close')}}</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonButton,
  IonCard, IonCardHeader, IonCardTitle, IonCardContent,
  modalController
} from "@ionic/vue";

import { bluetoothOutline } from 'ionicons/icons';

import { NetworkInterface } from '@awesome-cordova-plugins/network-interface';

import QrcodeVue from 'qrcode.vue'
import EventBus from '../../../services/EventBus';


export default {
  name: "MonitorConnectionModal",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSlides,
    IonSlide,
    IonButton,
    IonCard, IonCardHeader, IonCardTitle, IonCardContent,
    QrcodeVue,
  },
  setup() {
		return {
			bluetoothOutline
		};
	},
  data() {
    return {
      size: 200,
      isConnected: false,
      ip: 'localhost',
      webId: '123456',
      webPassword: '1234',
      bleService: '1234',
      bleUUID: 'abcd',
      bledebug: [],

      BLEstarting: false,
      BLEstarted: false,
      idxSetupPeripheral: -1,
    }
  },
  beforeMount() {
    // eslint-disable-next-line
    let that = this;
    try {
      NetworkInterface.getCarrierIPAddress().then(address => {console.log(address)});
      NetworkInterface.getWiFiIPAddress().then(address => {
        //alert(`getWiFiIPAddress(): ${JSON.stringify(address)}`);
        that.ip = address.ip;
      }).catch(e => {
        if (e == 'cordova_not_available') {/* desktop */}
        else {
          alert(`Unable to retrieve WiFi Address: ${JSON.stringify(e)}`);
        }
      });
    } catch(e) {
      console.error(e);
    }
  },
  mounted() {
    // eslint-disable-next-line
    let that = this;

    EventBus.addEventListener('connectionEvent', (event) => {
      let data = EventBus.getData(event);

      console.log("Got connection event!");

      that.isConnected = data.isConnected;
      that.closeMe();
    });
  },
  computed: {
    generateWebLink: function () {
      const connectData = {
        ip: this.ip,
        id: this.webId,
        pw: this.webPassword
      };

      return JSON.stringify(connectData);
    },
    generateBLELink: function () {
      const connectData = {
        ble: true,
        id: this.webId,
        pw: this.webPassword,
        bleService: this.bleService,
        bleUUID: this.bleUUID,
      };

      return JSON.stringify(connectData);
    },
    statusMessage: function() {
      if(this.isConnected) {
        return "Verbindung hergestellt";
      } else {
        return "Warte auf Verbindung...";
      }
    },
  },
  methods: {
    closeMe: function() {
      modalController.dismiss();
    }
  }
};
</script>

<style scoped>
ion-slide >:first-child {
    width: 100%;
    height: 100%;
    min-height: 100%;
}


.pulse {
  border-radius: 10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
  color: blue;
  opacity: 0.3;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
</style>